.About1 {
    background-color: #404e6a;
    color: #fff;
    border: solid #141515;
    margin-bottom: 2px;
}

.AboutUL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
}


.AboutLI {
    display: flex;
    align-items: center;
    justify-content: center;
    border: thin solid #141515;
    margin: 2px;
    padding: 5px;
    list-style: none;
}

.AboutLink {
    color: #fff;
}

.AboutH3 {
    margin: 5px;
    text-align: center;
}