.Contact1 {
  background-color: #7498de;
  color: white;
  border: solid #141515;
  margin-bottom: 2px;
  min-height: 150px;
}

.ContactH3 {
  margin: 5px;
  text-align: center;
}

.ContactConnectUL {
  /* border: solid pink; */
  padding: 5px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}

.HLink {
  color: white;
}
