#SignIn1 {
    background-color: #6c9aea;
    color: white;
    border: solid hsl(180, 2%, 8%);
    margin: 5px;
}


.SignInH3 {
    margin: 5px;
    text-align: center;
}

#SignInForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.SignInLabel {
    border: solid thin #000000;
    margin: 3px;
    padding: 5px;
    width: 310px;
} 

