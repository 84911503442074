.Ford1 {
    background-color: #3b6084;
    padding: 2px;
    color: white;
    border: solid #141515;
    margin-bottom: 2px;
}

.FordLink {
    color: white;
}

.FordH3 {
    margin: 15px;
    text-align: center;
}

.FordUL {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.FordLI {
    margin: 10px;
}


.FordImg1 {
    border: thin solid #dfebeb;
    margin: 5px;
    height: auto;
    max-height: 260px;
    width: auto;
    max-width: 300px;
}