.Search1 {
    display: flex;
    flex-direction: column;
    margin: 2px;
    width: 50%;
    max-width: 350px;
}


.SearchInput {
    padding: 5px;
    border: solid thin #000;
}