.Nav1 {
    margin-top: 15px;
}


.NavUL {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
    color: white;
    
}

.NavLI {
    border-left: thin solid #141515;
    padding: 5px;
}