
.Top1 {
    background-color: #637494;
    border: solid #141515;
    margin-bottom: 2px;
    color: white;
}


.Top2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}


.TopImg {
    justify-self: end;
    margin: 5px;
    border-radius: 180px;
    height: 100px;
    width: 100px;
}

.TopH1 {
    margin: 5px 5px 5px 15px;
}