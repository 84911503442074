#Register1 {
    background-color: #cad2e0;
    color: white;
    border: solid #141515;
    margin: 5px;
}


.RegisterH3 {
    margin: 5px;
    text-align: center;
}

#RegisterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
} 

.RegisterLabel {
    border: solid thin #000000;
    margin: 3px;
    padding: 5px;
    width: 310px;
}

.RegisterInputSubmit {
    align-items: center;
}